import React, { useEffect, useRef, useState } from "react"
import AccountLayout from "../../../components/accountLayout"
import BeneficialOwner from "../../../components/beneficialOwner"

const BeneficialOwnerPage = () => {
  const [isDisabled, setIsDisabled] = useState(true)
  const formInputRef = useRef({})

  useEffect(() => {
    formInputRef.current = { ...formInputRef.current, formLevel: 4 }
  }, [])

  console.log(formInputRef.current)
  return (
    <AccountLayout
      to="/account-details/single-professional-host/beneficial-owner-review"
      title="Add beneficial owners"
      formInput={formInputRef.current}
      isDisabled={isDisabled}
    >
      <form id="form-id">
        <BeneficialOwner formInputRef={formInputRef} setIsDisabled={setIsDisabled} />
      </form>
    </AccountLayout>
  )
}

export default BeneficialOwnerPage
